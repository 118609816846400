<template>
  <v-container fluid>
    <v-sheet elevation="5">
      <h4 class="font-weight-bold ml-5 pt-5 mb-5">{{userAccess.canUpdate ? 'UBAH -' : ''}}{{form.parameterName}}</h4>
      <vue-editor class="border" v-model="form.parameterValue" :editorToolbar="customToolbar"></vue-editor>
      <v-row class="mt-10" v-if="userAccess.canUpdate">
        <v-col cols="12" class="d-flex justify-end">
          <v-btn color="red" class="white--text mr-3" @click="$router.go(-1)">{{$_strings.common.CANCEL}}</v-btn>
          <v-btn class="primary mr-5" @click="submit">{{$_strings.common.SAVE}} </v-btn>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import { VueEditor } from 'vue2-editor';

export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      customToolbar: [[]],
      form: {
        parameterValue: '',
      },
    };
  },
  computed: {
    wordingId() {
      return this.$route.params.wordingId;
    },
    isEditing() {
      return !!this.wordingId;
    },
  },
  methods: {
    fetchWordingDetail() {
      this.$root.$loading.show();
      this.$_services.wording.detailInfo(this.wordingId)
        .then((result) => {
          this.form = result.data;
        }).catch((err) => {
          if (err.data && err.data.message) {
            this.$dialog.notify.error(err.data.message);
          }
          this.$router.go(-1);
        })
        .finally((e) => {
          this.$root.$loading.hide();
        });
    },
    submit() {
      this.$root.$loading.show();
      this.$_services.wording.editWording(this.form, this.wordingId)
        .then((res) => {
          this.$dialog.notify.success('Berhasil memperbarui data');
          this.$router.go(-1);
        })
        .catch((err) => {
          if (err.data && err.data.message) {
            this.$dialog.notify.error(err.data.message);
          }
        })
        .finally(() => {
          this.$root.$loading.hide();
        });
    },
  },
  created() {
    this.fetchWordingDetail();
  },
};
</script>

<style lang="scss">
.ql-toolbar {
  display: none;
}
.border {
  border: 1px solid rgb(192, 191, 191);
}
</style>
